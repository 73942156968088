import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Helpers } from './helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'riding-solo';
  static loading:boolean;
  constructor(private _router:Router){}
ngOnInit(){
  AppComponent.loading = false;
  this._router.events.subscribe(route => {
    if (route instanceof NavigationStart) {
      Helpers.setLoading(true);
      Helpers.bodyClass('fixed-navbar has-animation sidebar-mini');
    }
    if (route instanceof NavigationEnd) {
      window.scrollTo(0, 0);

      // Initialize page: handlers ...
      Helpers.initPage();
      Helpers.setLoading(false);
    }
  });
  Helpers.setLoading(false);
}
get LoaderState(){
  return AppComponent.loading;
}
}
