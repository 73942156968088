<div id="footer">
    <div class="page-container">
        <div class="footer-top-sec">
            <h4>Quick Links</h4>
            <ul class="quick-links">
                <li><a [routerLink]="['./']">Home</a></li>
                <li><a [routerLink]="['/aboutus']">About Us</a></li>
                <li><a [routerLink]="['./tripwithcelebrity']">Travel With Bloggers</a></li>
                <li><a [routerLink]="['./partner-with-us']">Partner with Us</a></li>
                <li><a href="http://new.ridingsolo.in/blog/">Blog</a></li>
                <li><a [routerLink]="['/testimonials']">PR & Marketing</a></li>
                <li><a [routerLink]="['./contactus']">Contact Us</a></li>
                <li><a [routerLink]="['/termsandcondition/terms']">Terms & Conditions</a></li>
                <li><a [routerLink]="['/privacypolicy']">Privacy Policy</a></li>
            </ul>
        </div>
        <!-- /FOOTER TOP SECTION-->
        <div class="footer-mid-sec">
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <h4>Payments we process</h4>
                    <img src="/assets/images/common/foo-payments.png" alt="foo-payments"> </div>
                <div class="col-sm-12 col-md-6">
                    <h4>Certifications</h4>
                    <img src="/assets/images/common/foo-certifications.png" alt="foo-certifications"> </div>
                <div class="col-sm-12 col-md-2">
                    <h4>Recommended by</h4>
                    <img src="/assets/images/common/foo-recommendeds.png" alt="foo-recommendeds"> </div>
            </div>
        </div>
        <!-- /FOOTER MID SECTION-->
        <div class="footer-bot-sec">
            <div class="row">
                <div class="col-sm-12 col-md-5">
                    &copy; 2017 - 2023, Riding Solo All Rights Reserved.
                </div>
                <div class="col-7 col-md-4 social-links d-flex justify-content-center">
                    <ul>
                        <li><a class="facebook" target="_blank" href="https://www.facebook.com/ridingsoloIN/"><i class="ti ti-facebook"></i><div class="tooltip">Facebook</div></a></li>
                        <li><a class="twitter" target="_blank" href="https://twitter.com/ridingsoloin"><i><img src="/assets/images/common/twitter.svg" alt="twitter" style="width: 15px; margin-top:-3px"> </i><div class="tooltip">Twitter</div></a></li>
                        <li><a class="youtube" target="_blank" href="https://www.youtube.com/channel/UCrsXjdR1Vr1D1TcfUX28k3g"><i class="ti ti-control-play"></i><div class="tooltip">Youtube</div></a></li>
                        <li><a class="instagram" target="_blank" href="https://www.instagram.com/ridingsolo.in/"><i class="ti ti-instagram"></i><div class="tooltip">Instagram</div></a></li>
                    </ul>
                    <div class="clear"></div>
                </div>
                <div class="col-5 col-md-3 siri">
                    <a href="http://www.siriinnovations.com/" target="_blank"><img src="/assets/images/common/siri.png" alt="siri" title="Siri It Innovations"></a>
                </div>
            </div>
        </div>
        <!-- /FOOTER BOTTOM SECTION-->
    </div>
</div>