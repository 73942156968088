<div class="page-heading">
    <h2 class="page-title">{{title}}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="['/home']"><i class="lni lni-home"></i></a>
        </li>
        <li class="breadcrumb-item text-red">{{title}}</li>
    </ol>
    <div class="clear"></div>
</div>
<div class="page-description">
    <div class="row">
      <div [innerHTML]="data" class="col-md-12">
      </div>        
    </div>
</div>