import {
  Injectable, Inject
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {

  }
  get(url:string){  return this.http.get(
   url,

    this.headers
  );}
  post(url:string,data:any){
    return this.http.post(
   url,
      data,
      this.headers
    );
  }

}
