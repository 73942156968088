import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {
  paymentStatus: EventEmitter<string> = new EventEmitter();

    private paymentId: string;


  constructor() { }

  payWithRazorpay(amount: number, order_id: string, name: string, email: string, phone: string, callback_url: string) {
    const options = {
      key: 'rzp_live_UPBHfdZUYbSdmv',
      amount: amount,
      currency: 'INR',
      name: name,
      description: 'Trek Booking Payment',
      order_id: order_id,
      prefill: {
        name: name,
        email: email,
        contact: phone
      },
      notes: {
        address: 'Trek Booking'
      },
      theme: {
        color: '#3399cc'
      },
      handler: (response: any) => { // Arrow function to preserve 'this' context

        // handle the response here
        fetch(callback_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === '200') {
            this.paymentId = data.payment_id;

            this.paymentStatus.emit('success');
          } else {
            this.paymentStatus.emit('failure');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
                    this.paymentStatus.emit('failure');

        });
      },
      modal: {
        ondismiss: () => { // Arrow function to preserve 'this' context
          console.log('Checkout form closed');
        }
      }
    };

    const rzp = new (window as any).Razorpay(options);
    rzp.open();
  }
  getPaymentId() {
    return this.paymentId;
  }
}
