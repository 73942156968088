
import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: "root" })
export class CryptoHeler {
  tokenFromUI: string = "$R!d!ngs0l0$!r!$";

  request: string;
  responce: string;
  constructor() {

  }
  encrypt(request: string) {
  
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(request), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }
  decrypt(request: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    return CryptoJS.AES.decrypt(
      request, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
  }
}
