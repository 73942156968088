<div class="mob-view-number"><a href="tel:7075200000"> <i class="lni lni-whatsapp"></i>  <span>70752 00000</span>
</a></div>
<div id="header">
    <div id="top-bar">
        <div class="page-container">
            <div class="logo">
                <!-- <div class="menu-icon"> <a href="javascript:;" class="sidebar-toggler js-sidebar-toggler"><i
                            class="lni lni-menu"></i></a> </div> -->
                <h1>
                    <a [routerLink]="['/home']"><img src="/assets/images/common/logo.png" alt="logo"
                            title="Riding Solo - Home"></a>
                </h1>
            </div>             
            <!-- <div class="top-links contact">
                <ul>
                    <li>
                        <label class="contact-text"> Call/WhatsApp </label>
                        <div class="contact-number"> 70752 00000 </div>
                    </li>
                </ul>
            </div> -->
            <!-- TOP BAR-->
            <div class="top-links">
                <ul>
                    <li>
                        <a> <i class="lni lni-whatsapp"></i> <span>70752 00000</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/tripwithcelebrity']"> <i class="lni lni-emoji-cool"></i> <span>Travel With Bloggers</span>
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/partner-with-us']"> <i class="lni lni-users"></i> <span>Partner with
                                Us</span> </a>
                    </li>
                   
                    <li>
                        <a href="https://www.ridingsolo.in/blog/"> <i class="lni lni-comments-alt"></i> <span>Blog</span> </a>
                    </li>
                    <li>
                        <a [routerLink]="['/contactus']"> <i class="lni lni-phone-set"></i> <span>Contact Us</span> </a>
                    </li>
                    <li *ngIf="!username" class="s-w-m">
                        <a (click)="login()"> <i class="lni lni-unlock"></i> <span>Sign In / Sign
                                Up</span> </a>
                    </li>
                    <li class="s-w-m"><a href="javascript:;" class="sidebar-toggler js-sidebar-toggler"><i
                                class="lni lni-user" *ngIf="username"></i>
                            <ng-container *ngIf="username">Hi, {{username}}</ng-container>
                        </a></li>
                    <!-- <li *ngIf="username" class="s-w-m dropdown"><a href="" class="dropdown-toggle"
                            data-toggle="dropdown">
                            <i class="lni lni-user"></i>{{username}} <b class="caret"></b></a>
                        <ul class="dropdown-menu">
                            <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
                            <li><a [routerLink]="['/profile']">Profile</a></li>
                            <li><a [routerLink]="['/booknow/transactionhistory']">Transaction History</a></li>
                            <li><a [routerLink]="['/booknow/bookinghistory']">Booking History</a></li>
                            <li><a [routerLink]="['/']" (click)="logOut()">LogOut</a></li>
                        </ul>
                    </li> -->

                </ul>
            </div>
            <div class="clear"></div>
        </div>
    </div>
    
    <!-- /TOP BAR-->
    <div id="header-space-block">
        <div id="bot-bar">
            <div class="page-container">
                <div class="riding-menu">
                    <ul>
                        <li [ngClass]="{'active':SelectedMenuType=='Leisure'}">
                            <a (click)="leisureTrips()"><img src="/assets/images/common/icon-menu-leisure-trips.png"
                                    alt="icon-menu-leisure-trips" title="Treks"><span>Leisure Trips</span></a>
                        </li>
                        <li [ngClass]="{'active':SelectedMenuType ==='Trek'}">
                            <a (click)="treks()"><img src="/assets/images/common/icon-menu-treks.png"
                                    alt="icon-menu-treks" title="Treks"><span>Treks</span></a>
                        </li>
                        <li [ngClass]="{'active':SelectedMenuType=='Trip'}">
                            <a (click)="bikeTrips()"><img src="/assets/images/common/icon-menu-bike-trips.png"
                                    alt="icon-menu-bike-trips" title="Treks"><span>Bike Trips</span></a>
                        </li>
                        
                        <li [ngClass]="{'active':SelectedMenuType=='Hostels'}">
                            <a (click)="hostels()"><img src="/assets/images/common/icon-menu-hostels.png"
                                    alt="icon-menu-hostels" title="Treks"><span>ATS</span></a>
                        </li>
                        <li [ngClass]="{'active':SelectedMenuType=='Expeditions'}">
                            <a (click)="expeditions()"><img src="/assets/images/common/icon-menu-expedition.png"
                                    alt="icon-menu-expedition" title="Treks"><span>Expedition</span></a>
                        </li>
                    </ul>
                </div>
                <!-- /RIDING MENU -->
                <div id="header-search" class="header-search" *ngIf="enableSearch">
                   <form [formGroup]="searchForm" autocomplete="off" (ngSubmit)="submit()">
                        <ul>
                            <li>
                                <i class="lni lni-search"></i>
                                <input type="text" class="custom-form-control" placeholder="Search by Terrain, {{SelectedMenuType}} Name, Month & Mores" id="name"
                                    typeaheadWaitMs="2000" formControlName="name" [typeahead]="searchSuggetionList"
                                    [typeaheadOptionsLimit]="10" [typeaheadMinLength]="2" (keyup)="searchList($event)">
                            </li>
                            <li
                                *ngIf="(urlPath=='/trek' ||urlPath=='/trip'||urlPath=='/expeditions')">
                               
                                <select class="custom-select-control" id="difficulty" formControlName="difficulty">
                                    <option value="">Select by Difficulty</option>
                                    <option  style="height:50px !important" *ngFor="let difficultylevel of difficultyList"
                                        value="{{difficultylevel.dificultyId}}">
                                        {{difficultylevel.difficultyName}}</option>
                                </select>
                               
                            </li>
                            <li *ngIf="(urlPath=='/hostels')">
                                <select class="custom-select-control" id="difficulty" formControlName="region">
                                    <option value="">Select by Region</option>
                                    <option *ngFor="let difficultylevel of regions"
                                        value="{{difficultylevel.regionId}}">
                                        {{difficultylevel.regionName}}</option>
                                </select>
                            </li>
                            <li>
                                <select class="custom-select-control" id="month" formControlName="month">
                                    <option value="">Select by Month</option>
                                    <option *ngFor="let month of monthsList" value="{{month.monthId}}">
                                        {{month.monthName}}
                                    </option>
                                </select>
                            </li>
                            <li *ngIf="(urlPath=='/trek' || urlPath=='/expeditions' || urlPath=='/' || urlPath=='/home' || urlPath=='/leisure')">
                                <select class="custom-select-control" id="slab" formControlName="slab">
                                    <option value="">Select by Slab</option>
                                    <option *ngFor="let slab of slabsList" value="{{slab.slabId}}">{{slab.slabName}}
                                    </option>
                                </select>
                            </li>
                            <li *ngIf="urlPath=='/'||urlPath=='/home'||urlPath=='/trip' || urlPath=='/leisure'|| urlPath=='/hostels'">
                                <select class="custom-select-control" id="slab" formControlName="terrain">
                                    <option value="">Select by Terrain</option>
                                    <option *ngFor="let slab of bikeTerrin" value="{{slab.terrain_id}}">
                                        {{slab.difficultyName}}
                                    </option>
                                </select>
                            </li>
                            <li>
                                <button class="custom-search-button" type="submit">
                                    Search
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!-- /BOT BAR-->
</div>