<div id="sidebar-collapse">
  <ul class="side-menu metismenu" id="metismenu">
    <li class="close-menu">
      <a
        ><i class="sidebar-item-icon lni lni-arrow-left"></i
        ><span class="nav-label">Back</span></a
      >
    </li>

    <!-- <li>
      <a [routerLink]="['/home']" routerLinkActive="router-link-active "
        ><i class="sidebar-item-icon lni lni-home"></i
        ><span class="nav-label">Home</span></a
      >
    </li>
    <li>
      <a [routerLink]="['/tripwithcelebrity']">
        <i class="sidebar-item-icon lni lni-emoji-cool"></i>
        <span class="nav-label">Trip with Celebrity</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/partner-with-us']">
        <i class="sidebar-item-icon lni lni-handshake"></i>
        <span class="nav-label">Partner with Us</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/aboutus']">
        <i class="sidebar-item-icon lni lni-users"></i>
        <span class="nav-label">About Us</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/blog']">
        <i class="sidebar-item-icon lni lni-comments-alt"></i>
        <span class="nav-label">Blog</span>
      </a>
    </li> -->
    <li>
      <a [routerLink]="['/dashboard']">
        <i class="sidebar-item-icon lni lni-dashboard"></i>
        <span class="nav-label">Dashboard</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/profile']">
        <i class="sidebar-item-icon lni lni-user"></i>
        <span class="nav-label">Profile</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/booknow/transactionhistory']">
        <i class="sidebar-item-icon lni lni-rupee"></i>
        <span class="nav-label">Transaction History</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/booknow/bookinghistory']">
        <i class="sidebar-item-icon lni lni-phone-set"></i>
        <span class="nav-label">Booking History</span>
      </a>
    </li>
    
    <li>
      <a [routerLink]="['/trek']">
        <i class="sidebar-item-icon lni lni-direction"></i>
        <span class="nav-label">All Treks</span>
      </a>
    </li>

    <!-- <li>
      <a href="javascript:; "
        ><i class="sidebar-item-icon ti lni lni-map-marker"></i
        ><span class="nav-label">By Region</span
        ><i class="ti ti-angle-left arrow"></i
      ></a>
      <ul *ngFor="let item of regions;let i =index;" class="nav-2-level" routerLinkActive="in ">
        <li><a (click)="navigate(item,'region')" >{{item.regionName}}</a></li>
      </ul>
    </li> -->
    <li>
      <a href="javascript:; "
        ><i class="sidebar-item-icon lni lni-sun"></i
        ><span class="nav-label">By Region</span
        ><i class="ti ti-angle-left arrow"></i
          ></a>
      <ul class="nav-2-level" routerLinkActive="in ">
        <li *ngFor="let item of regions;let i =index;"><a (click)="navigate(item,'region')" >{{item.regionName}}</a></li>
        <!-- <li><a (click)="navigate({ season:'Rainy',id:2 },'season')" >Rainy</a></li>
        <li><a (click)="navigate({ season:'Winter',id:3 },'season')">Winter</a></li> -->
      </ul>
    </li>
    <li>
      <a href="javascript:; "
        ><i class="sidebar-item-icon lni lni-sun"></i
        ><span class="nav-label">By Season</span
        ><i class="ti ti-angle-left arrow"></i
      ></a>
      <ul class="nav-2-level" routerLinkActive="in ">
        <li><a (click)="navigate({ season:'Summer',id:1 },'season')">Summer</a></li>
        <li><a (click)="navigate({ season:'Rainy',id:2 },'season')" >Rainy</a></li>
        <li><a (click)="navigate({ season:'Winter',id:3 },'season')">Winter</a></li>
      </ul>
    </li>

    <li>
      <a href="javascript:; "
        ><i class="sidebar-item-icon lni lni-flag"></i
        ><span class="nav-label">By Dificulty</span
        ><i class="ti ti-angle-left arrow"></i
      ></a>
      <ul class="nav-2-level" routerLinkActive="in ">
        <li><a (click)="navigate({ difficulty:'Easy',id:1 },'difficulty')" >Easy (for beginner)</a></li>
        <li><a (click)="navigate({ difficulty:'Moderate',id:3 },'difficulty')">Moderate (for fit beginner)</a></li>
        <li><a (click)="navigate({ difficulty:'Difficulty',id:5 },'difficulty')">Difficult (requires high fitness)</a></li>
      </ul>
    </li>
    <li>
      <a  (click)="logout()">
        <i class="sidebar-item-icon lni lni-direction"></i>
        <span class="nav-label">LogOut</span>
      </a>
    </li>
    <!-- <li><a [routerLink]="['/']" (click)="logOut()">LogOut</a></li> -->
  </ul>
</div>
<!-- <ul class="dropdown-menu">
  <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
  <li><a [routerLink]="['/profile']">Profile</a></li>
  <li><a [routerLink]="['/booknow/transactionhistory']">Transaction History</a></li>
  <li><a [routerLink]="['/booknow/bookinghistory']">Booking History</a></li>
  <li><a [routerLink]="['/']" (click)="logOut()">LogOut</a></li>
</ul> -->