import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SearchService } from 'src/app/services/search.service';
import { MenuType } from 'src/app/core/utils/menutype';
import { SessionConstants } from 'src/app/Utils/session-constants';
import { AppSessionManager } from 'src/app/Utils/session-manager';
import { LoginComponent } from 'src/app/modules/accounts/login/login.component';
import { Router, NavigationStart, Event as NavigationEvent, NavigationError, NavigationEnd } from '@angular/router';
import { HomepageServiceService } from 'src/app/services/homepage-service.service';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;
  username: string;
  searchForm: FormGroup;
  submitted: boolean;
  static menuType: string = 'Leisure';
  placeholder: string = 'By Leisure Name';
  event$: any;
  enableSearch: boolean = false;
  currentUrl: string;
  urlPath: string;
  difficultyList: any = [];
  monthsList: any = [];
  slabsList: any = [];
  bikeTerrin: any;
  selected: string;
  regions: any;
  searchSuggetionList: any[];

  constructor(private router: Router,
    private fb: FormBuilder, private route: Router, private appSessionManager: AppSessionManager, private searchService: SearchService,
    private homePageService: HomepageServiceService, private bsModalService: BsModalService, private sharedService: SharedService
  ) {
    // this.route.url = this.route.url;
    this.event$
      = this.route.events
        .subscribe(
          (event: NavigationEvent) => {
            const userSession = this.appSessionManager.getSession(SessionConstants.USER_SESSION);
            if ((this.route.url == '/trek') || (this.route.url == '/trip') || (this.route.url == '/leisure') || (this.route.url == '/hostels') || (this.route.url == '/expeditions') || (this.route.url == '/home'))
              this.enableSearch = true;
            if (userSession) {
              var userProfile = JSON.parse(userSession);
              this.username = userProfile.firstName;
            } else {
              this.username = null;
            }
            if (event instanceof NavigationEnd) {
              this.fetchInitialData();

              this.urlPath = event.url
              console.log('urlPath', this.urlPath)
              if ((event.url == '/')||(event.url == '/trek') || (event.url == '/trip') || (event.url == '/leisure') || (event.url == '/hostels') || (event.url == '/expeditions') || (event.url == '/home') || (this.route.url == '/home')) {
                this.enableSearch = true;
                if (event.url == '/home') {
                  HeaderComponent.menuType = 'Leisure';
                  this.placeholder = 'By Leisure Name';
                }
              } else {
                this.enableSearch = false;
              }
              if ((event.url == '/dashboard') || (event.url == '/booknow/transactionhistory') || (event.url == '/booknow/bookinghistory')) {
                // var element = document.getElementById("bot-bar");
                // if (!element.classList.contains("hide-bot-bar")) {
                //   var elem = document.getElementById("header-space-block");
                //   if (!elem.classList.contains("header-space")) {
                //     elem.classList.add("header-space");
                //   }
                //   element.classList.add("hide-bot-bar");
                // }
              }
              else {
                var element = document.getElementById("bot-bar");
                if (element.classList.contains("hide-bot-bar")) {
                  var elem = document.getElementById("header-space-block");
                  if (elem.classList.contains("header-space")) {
                    elem.classList.remove("header-space");
                  }
                  element.classList.remove("hide-bot-bar");
                }
              }
            }
          });
  }


  ngOnInit(): void {
    const userSession = this.appSessionManager.getSession(SessionConstants.USER_SESSION);
    console.log(HeaderComponent.menuType);
    if (this.route.url == '/home')
      HeaderComponent.menuType = 'Leisure';
    // HeaderComponent.menuType = '';
    if (userSession) {
      var userProfile = JSON.parse(userSession);
      this.username = userProfile.firstName;
    }

    this.submitted = false;
    this.searchForm = this.fb.group({
      name: [''],
      difficulty: [''],
      month: [''],
      slab: [''],
      terrain: [''],
      region: ['']
    })
    const searchModel = JSON.parse(sessionStorage.getItem(SessionConstants.SEARCH));
    if (searchModel) {
      this.searchForm.patchValue({
        name: searchModel.name
        , difficulty: searchModel.difficulty
        , month: searchModel.month
        , slab: searchModel.slab
        , menuType: this.getMenuType()
      });
    }
  }
  getBikeDifficultyList() {
    this.homePageService.getBikeDifficultyList(difficultylist => {
      this.difficultyList = difficultylist;
    });
  }
  getExpeditionDifficultyList(){
    this.homePageService.getExpeditionDifficultyList(difficultylist => {
      this.difficultyList = difficultylist;
    });
  }
  getRegion() {
    this.sharedService.getRegions(regions => {
      this.regions = regions;
    });
  }
  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
  }
  submit() {
    this.submitted = true;
    const data = {
      name: this.searchForm.controls['name'].value
      , difficulty: this.searchForm.controls['difficulty'].value
      , month: this.searchForm.controls['month'].value
      , slab: this.searchForm.controls['slab'].value
      , menuType: this.getMenuType()
      , action: 'onsearch'
      , terrain: this.searchForm.controls['terrain'].value,
      region: this.searchForm.controls['region'].value,
    };

    this.searchService.setSearchModel(data);
    // this.router.navigate(['search']);
    if (this.urlPath == '/home' || this.urlPath == '/') {
      // this.treks(1);
      this.placeholder = 'By Leisure Name';
      this.router.navigate([`/leisure`], { state: { issearch: true } })
      this.searchService.searchData(data);
    } else {
      if (!this.router.getCurrentNavigation()) {
        this.searchService.searchData(data);
      }
    }

  }

  get forms() {
    return this.searchForm.controls;
  }


  bikeTrips() {
    HeaderComponent.menuType = 'Trip';
    this.placeholder = 'By Trip Name';
    this.resetSearchForm();
    this.router.navigate(['/trip'])
  }

  leisureTrips() {
    HeaderComponent.menuType = 'Leisure';
    this.placeholder = 'By Leisure Trip Name';
    this.resetSearchForm();
    this.router.navigate(['/leisure'])
  }

  hostels() {
    HeaderComponent.menuType = 'Hostels';
    this.placeholder = 'By Hostel Name';
    this.resetSearchForm();
    this.router.navigate(['/hostels'])
  }

  expeditions() {
    HeaderComponent.menuType = 'Expeditions';
    this.placeholder = 'By Expedition Name';
    this.resetSearchForm();
    this.router.navigate(['/expeditions'])
  }

  treks(mode = null) {
    HeaderComponent.menuType = 'Trek';
    this.placeholder = 'By Trek Name';
    this.resetSearchForm();
    this.router.navigate(['/trek'])
    // if (mode == 1) {
    //   this.router.navigate([`/page/${MenuType.Treks}/${1}`])
    // } else {
    //   this.resetSearchForm();
    //   this.router.navigate(['/trek'])
    // }
  }

  resetSearchForm() {
    this.searchForm.controls['name'].patchValue('');
    this.searchForm.controls['difficulty'].patchValue('');
    this.searchForm.controls['slab'].patchValue('');
    this.searchForm.controls['month'].patchValue('');
    const data = {
      name: this.searchForm.controls['name'].value
      , difficulty: this.searchForm.controls['difficulty'].value
      , month: this.searchForm.controls['month'].value
      , slab: this.searchForm.controls['slab'].value
      , menuType: this.getMenuType()
      , action: 'onreset'
    };

    this.searchService.setSearchModel(data);
    this.searchService.searchData(data);
  }

  getMenuType() {
    if (HeaderComponent.menuType == 'Trek') {
      return MenuType.Treks;
    }
    else if (HeaderComponent.menuType == 'Leisure') {
      return MenuType.Leisures;
    }
    else if (HeaderComponent.menuType == 'Expeditions') {
      return MenuType.Expeditions;
    }
    else if (HeaderComponent.menuType == 'Hostels') {
      return MenuType.Hostels;
    }
    else if (HeaderComponent.menuType == 'Trip') {
      return MenuType.Trips;
    }
    else {
      return MenuType.Treks;
    }
  }

  get SelectedMenuType() {
    return HeaderComponent.menuType;
  }
  login() {
    this.route.navigateByUrl("/account/login")
  }

  fetchInitialData() {
    this.getDifficultyList();
    this.getMonthsList();
    this.getSlabsList();
    if (this.route.url == '/hostels') {
      this.getRegion();
      this.getHosterrain();
    } else if (this.route.url == '/trip') {
      this.getBikeDifficultyList();
      this.getTerrain();
    } else if (this.route.url =='/expeditions'){
      this.getExpeditionDifficultyList();
      this.getTerrain();
    } else if (this.route.url == "/leisure" || this.route.url == '/' || this.route.url == '/home') {
      // this.getLpregion();
      this.getLpterrain()
    }
  }
  getHosterrain() {

    this.homePageService.getLeisureTerrin().subscribe((res: any) => {
      this.bikeTerrin = res.terrains;
    });
  }

  getLpterrain() {
    this.homePageService.getLeisureTerrin().subscribe((res: any) => {
      this.bikeTerrin = res.terrains;
    })
  }
  getDifficultyList() {
    this.homePageService.getDifficultyList(difficultylist => {
      this.difficultyList = difficultylist;
    });
  }
  getMonthsList() {
    this.homePageService.getMonthList(monthslist => {
      this.monthsList = monthslist;
    });
  }
  getSlabsList() {
    this.homePageService.getSlabList(slabslist => {
      this.slabsList = slabslist;
    });
  }
  // getLpregion() {
  //   this.sharedService.getLpregions(regions => {
  //     this.regions = regions;
  //   });
  // }
  getTerrain() {
    this.homePageService.getBikeTerrin().subscribe((res: any) => {
      console.log('res', res);
      this.bikeTerrin = res.terrains;
    })
  }
  logOut() {
    sessionStorage.clear();
    this.username = null;
    this.router.navigate(['/home']);
    this.appSessionManager.clearSession(SessionConstants.USER_SESSION);

  }
  searchList(event) {
    if (event.target.value && event.target.value.length >= 2) {
      const data = {
        name: this.searchForm.controls['name'].value
        , difficulty: ''
        , month: ''
        , slab: ''
        , menuType: this.getMenuType()
        , action: 'onsearch'
        , terrain: ''
      };
      switch (HeaderComponent.menuType) {
        case "Trek":
          this.homePageService.getAllTreks(data).subscribe((data: any) => {
            data.treks ? this.searchSuggetionList = data.treks.map(i => i.trekTitle) : "";
          });
          break;
        case "Trip":
          this.homePageService.getTrips(data).subscribe((data: any) => {
            data.BikeTrips ? this.searchSuggetionList = data.BikeTrips.map(i => i.tripTitle) : "";
          });
          break;
        case "Leisure":
          this.homePageService.getLeisures(data).subscribe((data: any) => {
            data.leisurepackages ? this.searchSuggetionList = data.leisurepackages.map(i => i.pkgName) : "";
          });
          break;
        case "Hostels":
          this.homePageService.getHostels(data).subscribe((data: any) => {
            data.hostels ? this.searchSuggetionList = data.hostels.map(i => i.hostelName) : "";
          });
          break;
        case "Expeditions":
          this.homePageService.geExpeditions(data).subscribe((data: any) => {
            data.expeditions ? this.searchSuggetionList = data.expeditions.map(i => i.expeditionTitle) : "";
          });
          break;
        default:
          break;
      }
    }
    else {
      this.searchSuggetionList = [];
    }
    console.log("event", event.target.value.length, this.searchSuggetionList)
  }
  ngOnDestroy() {
    this.event$.unsubscribe();
  }
}
