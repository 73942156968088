import { Injectable } from '@angular/core';
import { ItemsTemplates } from '../core/models/itemstemplates';
import { environment } from 'src/environments/environment';
import { HttpClientService } from 'src/app/services/http-client.service';
import { MenuType } from '../core/utils/menutype';

@Injectable({
  providedIn: 'root'
})
export class HomepageServiceService {

  constructor(private httpService: HttpClientService) { }


  getUpcomingTreks(upcomingTreks: (treks: ItemsTemplates[]) => any) {
    this.httpService.get(environment.BASE_URL + environment.UPCOMING_TREKS+ '?offset=0&record_count=4').subscribe((data: any) => {            
      upcomingTreks(data.upcomingtreks.map(element => new ItemsTemplates(element.trekId, element.trekTitle, element.trekImage, element.trekFee, element.discountFee, element.trekDays, element.altitude + 'ft', element.difficultyName, element.tripReviews + ' Ratings', element.rating, element.tripReviews, element.trip_type, element.terrain)))
    })
  }

  getUpcomingTrek() {
    return this.httpService.get(environment.BASE_URL + environment.UPCOMING_TREKS+ '?offset=0&record_count=4');
  }
  getUpcomingTrips() {
    return this.httpService.get(environment.BASE_URL + environment.UPCOMING_TRIPS+ '?offset=0&record_count=4');
  }
  getUpcomingLeisureTrips() {
    return this.httpService.get(environment.BASE_URL + environment.UPCOMING_LEISURETRIPS+ '?offset=0&record_count=4');
  }
  getUpcomingExpeditionTrips() {
    return this.httpService.get(environment.BASE_URL + environment.UPCOMING_EXPEDITIONS+ '?offset=0&record_count=4');
  }


  getPopularTreks(req:any,popularTreks: (treks: ItemsTemplates[]) => any) {
    this.httpService.get(environment.BASE_URL + environment.POPULARTREK).subscribe((data: any) => {
      popularTreks(data.populartreks.map(element => new ItemsTemplates(element.trekId, element.trekTitle, element.trekImage, element.trekFee, element.discountFee, element.trekDays, element.altitude + 'ft', element.difficultyName, element.rating + ' Ratings', element.rating, element.tripReviews, element.trip_type, element.terrain)))
    })
  }
  getAllTreks(req: any) {
    return this.httpService.post(environment.BASE_URL + environment.SEARCHALLTREKS, req);
  }
  getTreks(req: any, popularTreks: (treks: ItemsTemplates[]) => any) {
    this.httpService.post(environment.BASE_URL + environment.SEARCHALLTREKS, req).subscribe((data: any) => {      
      popularTreks(data.treks ? data.treks.map(element => new ItemsTemplates(element.trekId, element.trekTitle, element.trekImage, element.trekFee, element.discountFee, element.trekDays, element.altitude + 'ft', element.difficultyName, element.rating + ' Ratings', element.rating, element.tripReviews,element.trip_type,element.terrain)) : [])

    })
  }
  getTrips(req: any) {
    return this.httpService.post(environment.BASE_URL + environment.POPULARTRIPS, req)
  }
  getPopularTrips(req: any, populatTrips: (trips: ItemsTemplates[]) => any) {

    this.httpService.get(environment.BASE_URL + environment.POPUARTRIP).subscribe((data: any) => {
      populatTrips(data.populartrips ? data.populartrips.map(element => new ItemsTemplates(element.tripId, element.tripTitle, element.tripImage, element.tripFee, element.discountFee, element.tripDays, element.altitude + 'ft', element.difficultyName, element.rating + ' Ratings', element.rating, element.tripReviews, element.trip_type, element.terrain)) : [])
    })
  }
  geExpeditions(req: any) {
   return this.httpService.post(environment.BASE_URL + environment.POPULAREXPEDITIONS, req);
  }
  getPopularExpeditions(req: any, popularExpeditions: (trips: ItemsTemplates[]) => any) {
     
    this.httpService.get(environment.BASE_URL + environment.POPUlAREXPEDITION).subscribe((data: any) => {
      popularExpeditions(data.popularexpedition ? data.popularexpedition.map(element => new ItemsTemplates(element.expeditionId, element.expeditionTitle, element.expeditionImage, element.expeditionFee, '', element.expeditionDays, element.altitude + 'ft', element.difficultyName, element.rating + ' Ratings', element.rating, element.tripReviews, element.trip_type, element.terrains)) : [])
    })


  }
  getPopularHostels(req: any, popularExpeditions: (trips: ItemsTemplates[]) => any) {
    this.httpService.post(environment.BASE_URL + environment.SEARCHHOSTELS, req).subscribe((data: any) => {
      popularExpeditions(data.hostels ? data.hostels.map(element => new ItemsTemplates(element.id,element.hostelName, element.logo, '', '', '', 'ft', '', '0.0 Ratings', '', '',element.trip_type,element.terrain,'','','','','','','','','','','','','','','','','','','',element.hostelView,element.city)) : [])

    })


  }
  getHostels(req: any){
    return this.httpService.post(environment.BASE_URL + environment.SEARCHHOSTELS, req);
  }
  getLeisures(req: any) {
   return this.httpService.post(environment.BASE_URL + environment.SEARCHLEISURES, req)
  }
  getPopularLeisures(req: any, leisures: (trips: ItemsTemplates[]) => any) {
    this.httpService.get(environment.BASE_URL + environment.POPULARLESUIRE).subscribe((data: any) => {
      leisures(data.popularleisuretrips ? data.popularleisuretrips.map(element => new ItemsTemplates(element.leisureId, element.pkgName, element.pkgImage, element.singlePrice, '', '', 'ft', '', '0.0 Ratings', '', '', element.trip_type, element.terrain,'','','','','','','','','','','','','','','','','','','','',element.hotelLocation,element.pkgDays)) : [])
    })
  }

  getOffers(offers: (offers: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.OFFERS).subscribe((data: any) => {
      offers(data.offers);
    });
  }
  getExperts(experts: (experts: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.TRAVELEXPERTS).subscribe((data: any) => {
      experts(data.expertdetails);
    });
  }
  getReviews(reviews: (reviews: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.TREKREVIEWS).subscribe((data: any) => {
      reviews(data.trek_reviews);
    });
  }

  getBlogArticles(articles: (articles: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.GETBLOGARTICLES).subscribe((data: any) => {
      articles(data.blog);
    });
  }

  getBlogDetails(id: any, articles: (articles: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.GETBLOGARTICLESDETAILS + '/' + id).subscribe((data: any) => {
      articles(data.blog);
    });
  }

  getSearchData(url: any, data: any, menuType: MenuType, searchData: (searchData: any, status: any, message: any) => any) {
    this.httpService.post(url, data).subscribe((data: any) => {
      if (menuType == MenuType.Treks) {
        searchData(data.treks, data.status, data.message);
      }
      if (menuType == MenuType.Trips) {
        searchData(data.BikeTrips, data.status, data.message);
      }
      if (menuType == MenuType.Expeditions) {
        searchData(data.Expeditions, data.status, data.message);
      }
      if (menuType == MenuType.Leisures) {
        searchData(data.Leisures, data.status, data.message);
      }
      if (menuType == MenuType.Hostels) {
        searchData(data.Hostels, data.status, data.message);
      }




    });
  }
  recentPosts(posts: (posts: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.RECENTPOSTS).subscribe((res: any) => {
      posts(res.recentposts);
    });
  }
  unknowPaths(posts: (posts: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.LEADSTOUNKNOWNPATHS).subscribe((res: any) => {
      posts(res.articles);
    });
  }

  handleUpcomingData(value: any, data: (data: any) => any) {
    switch (value) {
      case "Treks":
        // this.getPopularTreks(r(res: any) => {
        //   data(res);
        // });
        break;
      case "Bike":
        this.getPopularTrips({
          "search_keyword": "",
          "difficulty": "",
          "month": "",
          "budget": "",
          "offset": "0",
          "record_count": "15"
        }, (res: any) => {
          data(res);
        });
        break;
      case "Leisure":
        this.getPopularLeisures({
          "search_keyword": "",
          "difficulty": "",
          "month": "",
          "budget": "",
          "offset": "0",
          "record_count": "15"
        }, (res: any) => {
          data(res);
        });
        break;
      case "Hostels":
        this.getPopularHostels({
          "search_keyword": "",
          "difficulty": "",
          "month": "",
          "budget": "",
          "offset": "0",
          "record_count": "15"
        }, (res: any) => {
          data(res);
        });
        break;
      case "Expeditions":
        this.getPopularExpeditions({
          "search_keyword": "",
          "difficulty": "",
          "month": "",
          "budget": "",
          "offset": "0",
          "record_count": "15"
        }, (res: any) => {
          data(res);
        });
        break;
      default:
        break;
    }


  }

  getDifficultyList(difficulties: (difficulties: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.GETDIFFICULTY).subscribe((res: any) => {
      difficulties(res.difficulties);
    });
  }
  getBikeDifficultyList(difficulties: (difficulties: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.BIKEDIFFICULTY).subscribe((res: any) => {
      difficulties(res.difficulties);
    });
  }
  getExpeditionDifficultyList(difficulties: (difficulties: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.EXPEDITIONDIFFICULTY).subscribe((res: any) => {
      difficulties(res.difficulties);
    });
  }

  getMonthList(months: (months: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.GETMONTHS).subscribe((res: any) => {
      months(res.months);
    });
  }
  getSlabList(slabs: (slabs: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.GETSLABS).subscribe((res: any) => {
      slabs(res.slabs);
    });
  }
  getBikeTerrin(){
    return this.httpService.get(environment.BASE_URL + environment.BIKETERRIN);
  }
  getLeisureTerrin(){
    return this.httpService.get(environment.BASE_URL + environment.LEISURETERRIN);
  }
getHostelTerrain(){
  return this.httpService.get(environment.BASE_URL + environment.LEISURETERRIN);

}

  filterAllTreks(req: any, Treks: (treks: ItemsTemplates[]) => any) {
    this.httpService.post(environment.BASE_URL + environment.FILTERTREKS, req).subscribe((data: any) => {
      Treks(data.treks ? data.treks.map(element => new ItemsTemplates(element.trekId, element.trekTitle, element.trekImage, element.trekFee, '', element.trekDays, element.altitude + 'ft', element.difficultyName, element.tripReviews + ' Ratings', element.rating, element.tripReviews,element.trip_type,element.terrain)) : [])
    })
  }
  filterAllLeisures(req: any, leisures: (leisures: ItemsTemplates[]) => any) {
    this.httpService.post(environment.BASE_URL + environment.FILTERLEISURE, req).subscribe((data: any) => {
      leisures(data.leisurepackages ? data.leisurepackages.map(element => new ItemsTemplates(element.leisureId, element.pkgName, element.pkgImage, element.singlePrice, '', '', 'ft', '', ' Ratings', '', element.trip_type, '')) : [])
    })
  }
  filterAllTrips(req: any, trips: (trips: ItemsTemplates[]) => any) {
    this.httpService.post(environment.BASE_URL + environment.FILTERTRIPS, req).subscribe((data: any) => {
      trips(data.BikeTrips ? data.BikeTrips.map(element => new ItemsTemplates(element.tripId, element.tripTitle, element.tripImage, element.tripFee, '', element.tripDays, element.altitude + 'ft', element.difficultyName, element.tripReviews + ' Ratings', element.rating, element.tripReviews,element.trip_type,element.terrain)) : [])
    })
  }
  filterAllExpeditions(req: any, expeditions: (expeditions: ItemsTemplates[]) => any) {
    this.httpService.post(environment.BASE_URL + environment.FILTEREXPEDITIONS, req).subscribe((data: any) => {
      expeditions(data.expeditions ? data.expeditions.map(element => new ItemsTemplates(element.expeditionId, element.expeditionTitle, element.expeditionImage, element.expeditionFee, '', element.expeditionDays, element.altitude + 'ft', element.difficultyName, element.tripReviews + ' Ratings', element.rating, element.tripReviews,element.terrain)) : [])
    })
  }
  getcelebritytrips(start,end){
    return this.httpService.get(environment.BASE_URL+environment.GETCELEBRITYTRIPS + '?offset='+start + '&record_count=' +end)
  }
  getcelebritytripdetails(id){
    return this.httpService.get(environment.BASE_URL+environment.GETCELEBRITYTRIPSDETAILS + '/' + id)
  }
  insertcelebritytripenquiry(data){
    return this.httpService.post(environment.BASE_URL+environment.INSERTCELEBRITYTRIPENQUIRY,data)
  }

}
