<div class="page-heading">
    <h2 class="page-title">{{title}}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="['/home']"><i class="lni lni-home"></i></a>
        </li>
        <li class="breadcrumb-item">{{title}}</li>
    </ol>
    <div class="clear"></div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-3 mb-20">
        <div class="d-box d-box1">
            <div class="d-info">
                <h6>Upcoming </h6>
                <h3>{{upcomingTreks}}</h3>
                <img src="/assets/images/dashboard/icon-upcoming-treks.png" alt="icon-upcoming-treks">
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 mb-20">
        <div class="d-box d-box2">
            <div class="d-info">
                <h6>Ongoing </h6>
                <h3>{{ongoingTreks}}</h3>
                <img src="/assets/images/dashboard/icon-ongoing-treks.png" alt="icon-ongoing-treks">
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 mb-20">
        <div class="d-box d-box3">
            <div class="d-info">
                <h6>Completed </h6>
                <h3>{{completedTreks}}</h3>
                <img src="/assets/images/dashboard/icon-completed-treks.png" alt="icon-completed-treks">
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 mb-20">
        <div class="d-box d-box4">
            <div class="d-info">
                <h6>Total </h6>
                <h3>{{totalTreks}}</h3>
                <img src="/assets/images/dashboard/icon-total-treks.png" alt="icon-total-treks">
            </div>
        </div>
    </div>
</div>
<div class="flex-box">
    <h2>Upcoming Treks</h2>
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th> Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let trek of treks;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{trek.title}}</td>
                    <td>{{trek.startDate}}</td>
                    <td>{{trek.endDate}}</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>

<div class="flex-box">
    <h2>Advance Payment</h2>
    <div class="table-responsive">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Payment</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of payments;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{payment.title}}</td>
                    <td><i class="lni lni-rupee"></i>{{payment.advancePayment}}</td>
                    <td><a class="custom-btn" (click)="onPaymentNow()" [ngClass]="{'disable':getEnable(payment)}"><span>Pay Now</span><i class="ti ti-angle-double-right"></i></a></td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
