
<div class="page-wrapper">
    <header>
        <app-header></app-header>
    </header>
    <nav class="page-sidebar" id="sidebar">
        <app-sidenav></app-sidenav>
    </nav>
    <div class="content-wrapper">
        <div class="page-container">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- /PAGE CONTAINER -->
    <footer>
        <app-footer></app-footer>
    </footer>
</div>
<!-- /PAGE WRAPPER -->

<div class="to-top"><i class="lni lni-arrow-up"></i></div>
