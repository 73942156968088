declare var toastr: any;
declare var jQuery: any;
declare var $: any;
declare var Clipboard: any;

export class Helpers {
  static initPage() { }
  static setLoading(loading: boolean) {
    const body = $('body');
    if (loading) {
      $('.preloader-backdrop').fadeIn(200);
    } else {
      $('.preloader-backdrop').fadeOut(200);
    }
  }

  static bodyClass(Class: string) {
    $('body').attr('class', Class);
    
  }

  

  static initLayout() {
    $('body').addClass('fixed-navbar has-animation sidebar-mini');
    // SIDEBAR ACTIVATE METISMENU
    $("#metismenu").metisMenu({
      toggle: false
    });

    // SIDEBAR TOGGLE ACTION
    $('.js-sidebar-toggler').click(function() {
      if ($('body').hasClass('drawer-sidebar')) {
        $('#sidebar').backdrop();
      } else {
        $('body').toggleClass('sidebar-mini');
        if (!$('body').hasClass('sidebar-mini')) {
          $('#sidebar-collapse').hide();
          setTimeout(function() {
            $('#sidebar-collapse').fadeIn(300);
          }, 200);
        }
      }
    });
  
  $(function () {  
      // drawer sidebar
      $('#_drawerSidebar').change(function(){
        $('body').removeClass('sidebar-mini');
          if( $(this).is(':checked') ) {
              $('body').addClass('drawer-sidebar');
              $('#sidebar-collapse').slimScroll({
                  height: '100%',
                  railOpacity: '0.9',
              });
          } else {
              $('#sidebar-collapse').slimScroll({destroy: true}).css({overflow: 'visible', height: 'auto'});
              $('body').removeClass('drawer-sidebar');
          }
      });
      $('.close-menu').click(function(){
        $('body').addClass('sidebar-mini');
      })
    });

    $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
          $('.to-top').fadeIn();
      } else {
          $('.to-top').fadeOut();
      }
  });

    $('.to-top').click(function() {
      $("html, body").animate({scrollTop:0},500);
    });
  }
}
