import { Component, OnInit,Renderer2, ChangeDetectorRef,NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'console';
import { AppComponent } from 'src/app/app.component';
import { Helpers } from 'src/app/helpers';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SessionConstants } from 'src/app/Utils/session-constants';
import { AppSessionManager } from 'src/app/Utils/session-manager';
import { environment } from 'src/environments/environment';
import { RazorpayService } from 'src/app/modules/tabed-page/booking/services/razorpay.service';

declare var Instamojo: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  title = 'Dashboard';
  upcomingTreks: string;
  ongoingTreks: string;
  completedTreks: string;
  totalTreks: string;
   paymentId:any;
  treks: object[];
  payments: any[];
  constructor(
    private route: Router,
    private appSessionManager: AppSessionManager,
    private dashboardService: DashboardService,
    private sessionManager: AppSessionManager,
    private razorpayService: RazorpayService, 
    private cdr: ChangeDetectorRef,
      private renderer: Renderer2, 
      private ngZone: NgZone
  ) {
    if (!sessionStorage.getItem('userid')) {
      this.route.navigate(['/account/login']);
    }
  }

  ngOnInit(): void {
    AppComponent.loading = true;
    this.upcomingTreks = '0';
    this.ongoingTreks = '0';
    this.completedTreks = '0';
    this.totalTreks = '0';
    this.loadRazorpayScript();
    this.razorpayService.paymentStatus.subscribe(status => {
      this.ngZone.run(() => {
        if (status === 'success') {
           this.paymentId = this.razorpayService.getPaymentId();
          this.sessionManager.setSession('paymentId', this.paymentId);

          this.route.navigate(['/booknow/transactionhistory']);
        } else {
          this.route.navigate(['/dashboard']);
        }
        this.cdr.detectChanges();
      });
    });

    

    var userProfile = JSON.parse(
      this.appSessionManager.getSession(SessionConstants.USER_SESSION)
    );

    this.dashboardService
      .getDashboard({ userId: sessionStorage.getItem('userid'), type: 1 })
      .subscribe((res: any) => {
        console.log(res, 'Res');
        AppComponent.loading = false;
        this.upcomingTreks = res.Upcoming;
        this.ongoingTreks = res.OnGoing;
        this.completedTreks = res.Completed;
        this.totalTreks = res.Total;
        this.treks = res.UpComingDetails;
        this.payments = res.AdvancedPaymentsDetails;
      });
  }

  getEnable(payment: any) {
    let today = new Date();
    let bokingEndDate = new Date(payment.endDate);
    if (bokingEndDate < today) {
      return true;
    }
  }

loadRazorpayScript() {
    const script = this.renderer.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      console.log('Razorpay script loaded');
    };
    this.renderer.appendChild(document.body, script);
  }

  onPaymentNow() {

    if (this.payments && this.payments.length > 0) {
      let paymentData: any = {
        booking_id: this.payments[0].booking_id,
        original_amount: this.payments[0].original_amount,
        payment_type: '3',
        total_amount: this.payments[0].original_amount-this.payments[0].advancePayment,
      };
      this.dashboardService
        .post(environment.PROCEEDTOPAY, JSON.stringify(paymentData))
        .subscribe((res: any) => {
          if (res && res.status == 200) {
            this.sessionManager.setSession('bookingdata', {
              booking_id: this.payments[0].booking_id,
              trekFee: this.payments[0].original_amount,
              title: this.payments[0].title,
              batch: '',
              trekId: this.payments[0].id,
              noparticipants: '',
              requestId: res.requestid,
              URL: res.URL,
              userparticipants: [],
              rentalitems: [],
              addons: [],
              amountPaid: this.payments[0].advancePayment,
              dateOfTrek: this.payments[0].startDate,
            });
            const startPayment= () => {
             
             const amount = this.payments[0].original_amount-this.payments[0].advancePayment;
            const order_id = res.content.order_id; // example order ID
            const name = res.content.name;
            const email = res.content.email;
            const phone = res.content.phone;
            const callback_url = res.content.callback_url;


            this.razorpayService.payWithRazorpay(amount, order_id, name, email, phone, callback_url);


          }
          startPayment();
          }
        });
    }
  }
}
