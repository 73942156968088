// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://www.ridingsolo.in/riding',
  GET_LOGIN_OTP: '/ridingsolo/getloginotp',
  REGISTER_USER:'/ridingsolo/getregisteruserotp',
  REGIONS: '/ridingsolo/getregions',
  LPREGIONS : '/ridingsolo/getlpregions',
  VERIFY_OTP: '/ridingsolo/getverifyotp',
  RESEND_OTP: '/ridingsolo/resendotp',
  ABOUTUS: '/ridingsolo/aboutus',
  TRANSACTIONHISTORY: '/ridingsolo/gettransactiondetails',
  BOOKINGHISTORY: '/ridingsolo/getbookingdetails',
  RECENTPOSTS: '/ridingsolo/getrecentposts',
  LEADSTOUNKNOWNPATHS: '/ridingsolo/getunkonwnpath',
  DASHBOARD: '/ridingsolo/dashboard',
  UPDATEPROFILE: '/ridingsolo/updateuserdetails',
  INSERTUSER: '/ridingsolo/insertuserdetails',
  REGISTER: '/ridingsolo/insertuserdetails',
  SUBMITCONTACTFORM: '/ridingsolo/submitcontactus',
  PARTNERWITHUS: '/ridingsolo/submitpartnerwithus',
  CREATE_PARTICIPANT: '/ridingsolo/insertuserparticipantdetails',
  UPCOMING_TREKS: '/ridingsolo/upcomingtreks',
  UPCOMING_TRIPS: '/ridingsolo/upcomingtrips',
  UPCOMING_EXPEDITIONS: '/ridingsolo/upcomingexpeditions',
  UPCOMING_LEISURETRIPS: '/ridingsolo/upcomingleisuretrips',
  POPULARTREKS: '/ridingsolo/populartreks',
  POPULARTRIPS: '/ridingsolo/searchalltrips',
  POPULAREXPEDITIONS: '/ridingsolo/searchallexpeditions',
  SEARCHLEISURES: '/ridingsolo/searchallleisuretrips',
  SEARCHHOSTELS: '/ridingsolo/searchallhostels',
  GETHOSTELDETAILS:'/ridingsolo/gethosteldetails',
  OFFERS: '/ridingsolo/offers',
  POPULARLESUIRE:'/ridingsolo/popularleisuretrips?offset=0&record_count=4',
  POPULARTREK:'/ridingsolo/populartreks?offset=0&record_count=4',
  POPUARTRIP:'/ridingsolo/populartrips?offset=0&record_count=4',
  POPUlAREXPEDITION:'/ridingsolo/popularexpeditions?offset=0&record_count=4',
  TRAVELEXPERTS: '/ridingsolo/travelexperts',
  TREKREVIEWS: '/ridingsolo/trekreviews',
  ADDTREKREVIEWS: '/ridingsolo/addtrekreviews',
  REVIEWOTP:'/ridingsolo/getreviewotp',
  ADDTRIPREVIEWS: '/ridingsolo/addtripreviews',
  ADDEXPEDITIONREVIEWS: '/ridingsolo/addexpeditionreviews',
  ADDLPREVIEWS: '/ridingsolo/addleisuretripreviews',
  ADDHOSTELREVIEWS: '/ridingsolo/addexpeditionreviews',
  GETBLOGARTICLES: '/ridingsolo/blogarticles',
  GETBLOGARTICLESNEW: '/ridingsolo/getblogarticles',
  GETBLOGARTICLESDETAILS: '/ridingsolo/getblogarticledetails',
  SEARCHALLTREKS: '/ridingsolo/searchalltreks',
  TREKDETAILS: '/ridingsolo/gettrekdetails',
  TRIPDETAILS: '/ridingsolo/gettripdetails',
  EXPEDITIONDETAILS: '/ridingsolo/getexpeditiondetails',
  LEISUREDETAILS: '/ridingsolo/getleisuretripdetails',
  BOOKINGPROCESS: '/ridingsolo/bookingprocess',
  TRIPSBOOKINGPROCESS: '/ridingsolo/tripbookingprocess',
  LEISURESBOOKINGPROCESS: '/ridingsolo/lpbookingprocess',
  EXPEDITIONSBOOKINGPROCESS: '/ridingsolo/expeditionbookingprocess',
  EXPEDITIONDIFFICULTY: '/ridingsolo/expeditiondifficulties',
  INSERTBOOKINGDETAILS: '/ridingsolo/insertbookingdetails',
  INSERTTRIPBOOKINGDETAILS: '/ridingsolo/inserttripbookingdetails',
  INSERTEXPEDITIONBOOKINGDETAILS: '/ridingsolo/insertexpeditionbookingdetails',
  INSERTLEISUREBOOKINGDETAILS: '/ridingsolo/insertlpbookingdetails',
  PAYMENTPAGE: '/ridingsolo/paymentpage',
  TRIPPAYMENTPAGE: '/ridingsolo/trippaymentpage',
  EXPEDITIONPAYMENTPAGE: '/ridingsolo/expeditionpaymentpage',
  LPPAYMENTPAGE: '/ridingsolo/lppaymentpage',
  PROCEEDTOPAY: '/ridingsolo/proceedtopay',
  GETSTATES: '/ridingsolo/getstates',
  TREKSVALIDATEPROMOCODE: '/ridingsolo/validatepromocode',
  TRIPSVALIDATEPROMOCODE: '/ridingsolo/validatetrippromocode',
  EXPEDITIONSVALIDATEPROMOCODE: '/ridingsolo/validateexpeditionpromocode',
  LEISURESVALIDATEPROMOCODE: '/ridingsolo/validatelppromocode',

  GETDIFFICULTY: '/ridingsolo/difficulties',
  GETMONTHS: '/ridingsolo/getmonths',
  GETSLABS: '/ridingsolo/getslabs',
  FILTERTREKS: '/ridingsolo/filteralltreks',
  FILTERTRIPS: '/ridingsolo/filteralltrips',
  FILTEREXPEDITIONS: '/ridingsolo/filterallexpeditions',
  FILTERLEISURE: '/ridingsolo/filterallleisuretrips',
  PAYMENTTYPE: '/ridingsolo/paymettype',

  TERMSPRIVACY:'/ridingsolo/termsprivacy',
  TESTIMONIALS:'/ridingsolo/testimonials',
  PROMOCODES: '/ridingsolo/getpromocodes',
  BOOKINGSUCCESS: '/ridingsolo/bookingsuccess',
  PROCEEDTOPAYTRIP: '/ridingsolo/proceedtopaytrip',
  PROCEEDTOPAYLP: '/ridingsolo/proceedtopaylp',
  PROCEEDTOPAYEXP: '/ridingsolo/proceedtopayexpedition',

  GETCELEBRITYTRIPS:'/ridingsolo/getcelebritytrips',
  GETCELEBRITYTRIPSDETAILS:'/ridingsolo/getcelebritytripdetails',
  INSERTCELEBRITYTRIPENQUIRY:'/ridingsolo/insertcelebrityenquiry',

  INSERTQUOTE:'/ridingsolo/insertquote',

  CHECKROOMAVAILABILITY:'/ridingsolo/gethoteldetails',
  INSERTHOTELBOOKING:'/ridingsolo/inserthotelbooking',
  PROCESSHOTELBOOKING:'/ridingsolo/processhotelbooking',
  VIEWINVOICE:'/ridingsolo/viewinvoice',
  INVOICEBD:'/ridingsolo/viewinvoicebd',
  BIKETERRIN:'/ridingsolo/biketerrains',
  BIKEDIFFICULTY:'/ridingsolo/bikedifficulties',
  LEISURETERRIN:'/ridingsolo/lpterrains',
  MEDICALDECLARATION:'/ridingsolo/medicaldeclaration',
HOSTELTERRAIN:'/ridingsolo/hostelterrains',
LIABILITIES:'/ridingsolo/liabilities',
COUNTRY:'/ridingsolo/getcountries',
STATE:'/ridingsolo/getcountrystates',
CITY:'/ridingsolo/getstatecities',
MEDICALCERTIFICATE:'/ridingsolo/getmedicalcertificate/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in produc  tion mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
