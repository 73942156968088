import { Session } from 'protractor';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Console } from 'console';
import { CryptoHeler } from './CryptoHelper';

@Injectable({

  providedIn: 'root'

})
export class AppSessionManager {
  constructor(private crypto: CryptoHeler) {

  }
  public setSession(key: any, data: any) {
    try {
      var encryptedData = this.crypto.encrypt(data);
      sessionStorage.setItem(key, encryptedData);
    } catch (e) {
      return throwError('Something went wrong in the session');
    }
  }
  public getSession(key: any) {
    try {
      const session = sessionStorage.getItem(key);
      if (session) {
        return this.crypto.decrypt(session);
      }
      else{
        return null;
      }
    } catch (e) {
     return null;
    }
  }

  public clearSession(key:any){
    try {
      sessionStorage.removeItem(key);
    } catch (error) {

    }
  }
}
