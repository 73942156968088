import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SessionConstants } from 'src/app/Utils/session-constants';
import { AppSessionManager } from 'src/app/Utils/session-manager';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  regions:any;

  constructor(private sharedService:SharedService,private router: Router, private appSessionManager: AppSessionManager,) { }

  ngOnInit(): void {
    this.sharedService.getRegions(regions=>{
      this.regions = regions;
    });
  }
  logout(){
    sessionStorage.clear();
    this.appSessionManager.clearSession(SessionConstants.USER_SESSION);
    this.router.navigate(['/home']);
    window.location.reload();

  }
  navigate(value,type){
  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  this.router.onSameUrlNavigation = 'reload';
  type=='region'?this.router.navigate(['/trek'],{ state:{region: value}}):
  type=='season'?this.router.navigate(['/trek'],{ state:{season: value}}):
  this.router.navigate(['/trek'],{ state:{difficulty: value}})
  }

}
