<div class="page-heading">
    <h2 class="page-title">{{title}}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="['/home']"><i class="lni lni-home"></i></a>
        </li>
        <li class="breadcrumb-item text-red">{{title}}</li>
    </ol>
    <div class="clear"></div>
</div>
<div class="page-description">
    <div class="row">

        <div class="col-md-12">
            <h4>Introduction:</h4>
            <p>RidingSolo (Ridingsolo Hike and Trek Pvt Ltd.) (“we," "us," or "our") respects your privacy and is committed to protecting it through this Privacy Policy. This policy describes how we collect, use, and safeguard your personal information. Please read this policy carefully to understand our practices regarding your personal information.</p>

            <h4>1. Information We Collect:</h4>
            <p>We collect various types of information from you, including:</p>
                <ul class="unorder_list_content">
                    <li><span>Personal Information:</span> Name, email address, phone number, passport/Gov. ID details, city, age, gender and other identification for KYC procedures.</li>
                    <li><span>Financial Information: </span> Credit/debit card details for processing payments.</li>
                    <li><span>Travel Information:</span> Preferences, special requests, health conditions, and other travel-related information.</li>
                    <li><span>Content Information:</span> Photographs, videos, and other content that you share with us.</li>
                </ul>

                <h4>2. Use of Your Information:</h4>
                    <ul class="unorder_list_content">
                        <li><span>Providing Services:</span> To offer and provide our travel and accommodation services.</li>
                        <li><span>Marketing: </span> To send you promotions, special offers, and information about our services.</li>
                        <li><span>Compliance:</span> To comply with legal requirements and KYC procedures.</li>
                        <li><span>Promotion:</span> To use photographs and videos taken during trips for promotional purposes.</li>
                    </ul>

                    <h4>3. Consent and Opt-Out:</h4>
                    <p>By using our services, you consent to the collection, use, and disclosure of your personal information as described in this policy. You may opt-out of promotional communications at any time by following the instructions provided in those communications like whatsapp, email or via voice calls.</p>
       
       
                    <h4>4. Disclosure to Third Parties:</h4>
                    <p>We may disclose your personal information to third-party vendors, partners, and authorities as required by law or to provide our services. We do not sell or rent your personal information to third parties for marketing purposes without your explicit consent.</p>

                    <h4>5. Security:</h4>
                    <p>We implement appropriate security measures to protect your personal information. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.</p>

                    <h4>6. Access and Correction:</h4>
                    <p>You have the right to access and correct your personal information. Please contact us to do so.</p>

                    <h4>7. Cookies and Tracking:</h4>
                    <p>We may use cookies and similar technologies to track your use of our website and services.</p>

                    <h4>8. Compliance with Indian Law:      </h4>
                    <p>We comply with all applicable laws and regulations in India, including the Information Technology Act, 2000, and the associated rules regarding the collection, usage, and disclosure of personal information.</p>

                    <h4>9. Changes to Privacy Policy:</h4>
                    <p>We may change this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this policy periodically.</p>

                    <h4>10. Contact Information:</h4>
                    <p>For questions or concerns about this Privacy Policy, please contact us at <a href="explore@ridingsolo.in">explore@ridingsolo.in</a>.</p>

                    <h4>11. Consent for Photography and Video Content:</h4>
                    <p>By participating in our trips, you consent to the capture and use of photographs and videos for promotional and marketing purposes. If you do not consent to this usage, you must notify us in writing to <a href="explore@ridingsolo.in"> explore@ridingsolo.in</a> along with sending WhatsApp message of hand written self notified letter of do not usage for data we collect during your trip or stay with us.</p>
                        <ol class="order_list_content">
                            <li><span>Security</span></li>
                        </ol>
                   
                        <p>We take appropriate measures to protect personal information from unauthorized access, disclosure, alteration, or destruction.</p>
                        <ol class="order_list_content">
                            <li><span>Cookies and Other Tracking Technologies</span></li>
                        </ol>

                        <p>We may use cookies and other technologies to track user behavior on our website to enhance user experience and analyse website performance.</p>
                        <ol class="order_list_content">
                            <li><span>Your Rights and Choices</span></li>
                        </ol>

                        <p>You have the right to access, modify, or delete your personal information. Additionally, you may opt-out of marketing communications at any time.</p>
                        <ol class="order_list_content">
                            <li><span>Know Your Co-Traveler (KYCT) Feature</span></li>
                        </ol>


                        <p>We have introduced the Know Your Co-Traveler feature to foster connections among individuals who will be traveling together, aiming to enhance the overall travel experience. The feature may encompass the sharing of various details such as preferences, hobbies, interests, city, gender, age, and other pertinent information with co-travellers or any registered website users who click on the fixed departure date button on our website. Participation in the Know Your Co-Traveler feature is mandatory; however, customers can opt out of this feature by making a direct booking via email. In such cases, we will use dummy data for the user and send them the booking confirmation with the same dummy name. Rest assured, the original data will be securely stored with us and will not be shown, shared, or disclosed on any possible platforms.</p>

                </div>


            <!-- <div [innerHTML]="data" class="col-md-12"> </div>         -->
    </div>
</div>
