export class ItemsTemplates {
  id: string;
  title: string;
  image: string;
  regularPrice: string;
  offeredPrice: string;
  days: string;
  altitude: string;
  difficulty: string;
  ratingCount: string;
  rating: String;
  reviews: string;
  tripReviews:any;
  temperature:String;
  reportingPlace:string;
  dates:any;
  overview:string;
  detailItinerary:any;
  foodMisc:any;
  thingsToCarry:any;
  termsCondition:any;
  timeVisit:any;
  pickUpDrop:any;
  nights:any;
  howToReach:any;
  rentalItems:any;
  trekImages:any;
  trekFee:any;
  discountFee:any;
  gst:any;
  faq:any;
  terrain: any;
  facilities: any;
  city: any;
  pkgDays: any;
  triptype: any;
  public constructor(id: string, name: string, image: string, actualPrice: string, offerPrice?: string, days?: string,
    altitude?: string, difficulty?: string, ratingCount?: string, rating?: string, reviews?: string,triptype?:any,terrain?:any,temperature?:string,reportingPlace?:string,dates?:any,overview?:string,
    detailItinerary?:any,foodMisc?:any,thingsToCarry?:any,termsCondition?:any,timeVisit?:any,pickUpDrop?:string,nights?:any,
    howToReach?:any,rentalItems?:any,tripReviews?:any,trekImages?:any,trekFee?:any,discountFee?:any,gst?:any,faq?:any,facility?:any,city?:any,pkgDays?:any) {
    this.title = name;
    this.faq = faq;
    this.gst = gst;
    this.discountFee = discountFee;
    this.trekFee = trekFee;
    this.trekImages = trekImages;
    this.tripReviews = tripReviews;
    this.rentalItems = rentalItems;
    this.howToReach = howToReach;
    this.nights = nights;
    this.pickUpDrop = pickUpDrop;
    this.timeVisit = timeVisit;
    this.termsCondition = termsCondition;
    this.foodMisc = foodMisc;
    this.thingsToCarry = thingsToCarry;
    this.id = id;
    this.detailItinerary=detailItinerary;
    this.image = image;
    this.regularPrice = actualPrice;
    this.offeredPrice = offerPrice;
    this.dates=dates;
    this.days = days;
    this.overview = overview;
    this.altitude = altitude;
    this.difficulty = difficulty;
    this.ratingCount = ratingCount;
    this.rating = rating;
    this.reviews = reviews;
    this.temperature = temperature;
    this.reportingPlace=reportingPlace;
    this.terrain = terrain;
    this.facilities = facility;
    this.city = city;
    this.pkgDays=pkgDays,
    this.triptype = triptype
  }
}
