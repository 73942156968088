import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpService:HttpClientService) { }
  // dashboard(request: any
  //   , about: (res: any) => any) {
  //   this.httpService.post(environment.BASE_URL + environment.DASHBOARD, request).subscribe((data: any) => {
  //     console.log('data')
  //     about(data);
  //   });
  // }
  getDashboard(request){
    return this.httpService.post(environment.BASE_URL + environment.DASHBOARD, request);
  }
  get(controller: any) {
    return this.httpService.get(environment.BASE_URL + controller)
  }
  post(controller: any, obj: any) {
    return this.httpService.post(environment.BASE_URL + controller, obj)
  }
}
