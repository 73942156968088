import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientService } from './services/http-client.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccountsModule } from './modules/accounts/accounts.module';
import { BsModalService, BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { StoreModule } from '@ngrx/store';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AboutusComponent } from './modules/aboutus/aboutus.component';
import { LoaderComponent } from './core/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor.service';
import { TestimonialsComponent } from './modules/testimonials/testimonials.component';
import { TermsandconditionComponent } from './modules/termsandcondition/termsandcondition.component';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CertificateComponent } from './modules/certificate/certificate.component';
// import { ExternalRedirectComponent } from './modules/external-redirect/external-redirect.component';



@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
     HeaderComponent,
    FooterComponent,
    SidenavComponent,
    DashboardComponent,
    AboutusComponent,
    LoaderComponent,
    TestimonialsComponent,
    TermsandconditionComponent,
    PrivacypolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TypeaheadModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#06beb6',
      secondaryColour: '#48b1bf',
      tertiaryColour: '#00cdac'
    }),

    ToastrModule.forRoot(),
    StoreModule.forRoot({}, {})
  ],
  providers: [HttpClientService, BsModalService,
    BsModalRef, LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
],
    exports:[],
  bootstrap: [AppComponent]
})
export class AppModule { }
