import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private search = new BehaviorSubject<any>({});
  searchObservable = this.search.asObservable();
  searchModel:any;

  constructor() { }

  searchData(data){
    this.search.next(data);
  }

  public getSearchModel(){
    return this.searchModel;
  }

  public setSearchModel(searchModel:any){
    this.searchModel = searchModel;
  }
}
