import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StaticPageService } from 'src/app/services/static-page.service';

@Component({
  selector: 'app-termsandcondition',
  templateUrl: './termsandcondition.component.html',
  styleUrls: ['./termsandcondition.component.css']
})
export class TermsandconditionComponent implements OnInit {

  title = '';
  data: any;

  constructor(private _static: StaticPageService, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // var id = params.get('id');
      switch (params.get('id')) {
        case "terms":
          this.title = 'General Terms & Conditions';
          this._static.termsandconditionprivacy().subscribe((obj: any) => {
            this.data = obj?.termsprivacy?.termscondition?.description
          })
          break;
        case "medicaldeclaration":
          this.title = 'Medical self declaration clause';
          this._static.medicalselfdeclarationclause().subscribe((obj: any) => {
            this.data = obj?.medical?.pageDescription
          })
          break;
        case "liability":
          this.title = 'Liability clause';
          this._static.liabilities().subscribe((obj: any) => {
            this.data = obj?.liabilities?.pageDescription
          })
          break;
        default:
          break;
      }
    });



   
  }
}
