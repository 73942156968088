<div class="page-heading">
    <h2 class="page-title">{{title}}</h2>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="['/home']"><i class="lni lni-home"></i></a>
        </li>
        <li class="breadcrumb-item text-red">{{title}}</li>
    </ol>
    <div class="clear"></div>
</div>
<div class="page-description">
    <div class="row">

      <!-- <div [innerHTML]="data" class="col-md-6">

      </div> -->
      <div  class="col-md-12">

        <img src="/assets/images/common/our_journey.png" alt="our_journey" class="about-img">
        

        <h4>Our Journey:</h4>
        <p>Founded in 2016, RidingSolo has been at the forefront of creating life-changing travel experiences. What started as a dream has now evolved into one of the most trusted names in adventure travel, not just in India but across the globe.</p>

        <h4>Who We Are:</h4>
        <p>We are explorers, adventurers, and dreamers. At RidingSolo, our mission is to offer authentic and transformative travel experiences that go beyond the typical tourist trails. From enchanting treks in the Himalayas to thrilling bike trips along rugged terrains, we curate journeys that resonate with the spirit of adventure.</p>

        <h4>What We Do:</h4>
        <ul>
            <li><b>Trekking Expeditions:</b> Traverse untouched landscapes and witness nature in its purest form.</li>
            <li><b>Bike Trips:</b> Feel the wind in your hair as you ride through some of the most scenic routes.</li>
            <li><b>Leisure Trips:</b> Discover the hidden gems of a destination at your own pace.</li>
            <li><b>ATS Accommodations:</b> Our sister concern, ATS, offers a range of stay options that complement your travel experience, making it holistic and unforgettable.</li>
            <li><b>Educational Trips & Courses:</b> We offer specialized mountaineering courses for college and school groups, in collaboration with Nehru Institute of Mountaineering (NIM)</li>
        </ul>


</div>

<div  class="col-md-12 mt-50">
       
      
    <h4>Why Choose Us:</h4>

    <div class="row">
        <div class="col-sm-6">
            <div class="why_choose_us">
                <div class="why_choose_us-image">
                    <img src="/assets/images/common/why_icon1.svg" alt="">
                </div>
                <div class="why_choose_us-info">
                    <span>Expertise:</span>
                    <h4>Our team consists of certified and seasoned professionals who know the ins and outs of the travel world.</h4>
                    
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="why_choose_us">
                <div class="why_choose_us-image">
                    <img src="/assets/images/common/why_icon2.svg" alt="">
                </div>
                <div class="why_choose_us-info">
                    <span>Certified:</span>
                    <h4>We are proud to be registered with Incredible India, Indian Mountaineering Federation (IMF), and the Himachal Pradesh and Uttarakhand State Governments.</h4>
                    
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="why_choose_us">
                <div class="why_choose_us-image">
                    <img src="/assets/images/common/why_icon3.svg" alt="">
                </div>
                <div class="why_choose_us-info">
                    <span>Customized Packages:</span>
                    <h4>We understand that one size doesn’t fit all, and that’s why we offer customizable travel packages.</h4>
                    
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="why_choose_us">
                <div class="why_choose_us-image">
                    <img src="/assets/images/common/why_icon4.svg" alt="">
                </div>
                <div class="why_choose_us-info">
                    <span>Sustainable Travel:</span>
                    <h4>We are committed to responsible and sustainable tourism practices.</h4>
                    
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="why_choose_us">
                <div class="why_choose_us-image">
                    <img src="/assets/images/common/why_icon5.svg" alt="">
                </div>
                <div class="why_choose_us-info">
                    <span>Trusted Brand for Solo Travelers:</span>
                    <h4>We have served more than 100k clients.</h4>
                    
                </div>
            </div>
        </div>
    </div>
   

    </div>


    
    <div  class="col-md-12 mt-50 position-relative">

        <h4>Meet the Team:</h4>
        
        <div class="row">
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>B S Rajpurohit</h4>
                        <span>Director</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Pooja Singh</h4>
                        <span>Director</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Sanjana Rajpurohit</h4>
                        <span>Creative Head</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Dr. Sarita Singh</h4>
                        <span>CFO</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Rahul Singh</h4>
                        <span>COO</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Vinay Dilip</h4>
                        <span>HRM</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Babita Sheoran</h4>
                        <span>RM</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="experts">
                    <div class="expert-image">
                        <img src="/assets/images/team.png" alt="">
                    </div>
                    <div class="expert-info">
                        <h4>Akahnd Pratap Singh</h4>
                        <span>ATS Manager</span>
                    </div>
                </div>
            </div>
            
        </div>
       
       
    </div>



<div  class="col-md-12 mt-50">
        <h4>Our Clients:</h4>
        <p>We are proud to have collaborated with some of the most recognized names in the industry as their travel Partener :</p>
    
    
        <div class="row partners_logos">
            <div class="col-sm-2">
                <img src="/assets/images/common/hdfc_life.png" class="w-100" alt=""/>
            </div>
            <div class="col-sm-2">
                <img src="/assets/images/common/muthoot_fin.png" class="w-100" alt=""/>
            </div>
            <div class="col-sm-2">
                <img src="/assets/images/common/sap.png" class="w-100" alt=""/>
            </div>
            <div class="col-sm-2">
                <img src="/assets/images/common/allanasons.png" class="w-100" alt=""/>
            </div>
            <div class="col-sm-2">
                <img src="/assets/images/common/siri_logo.png" class="w-100" alt=""/>
            </div>
            <div class="col-sm-2">
                <img src="/assets/images/common/cello.png" class="w-100" alt=""/>
            </div>
        </div>

        </div>

        

        <div  class="col-md-12 mt-50">
    

        <h4>Official Travel & Educational Trip Partner:</h4>
        <p>We believe in imparting the love for adventure and travel among the younger generation. Here are some of the educational institutions we've partnered with bellow schools & more than 50 schools in PAN India:</p>
       
       
        <div class="row partners_logos">
            <div class="col-sm-2 text-center">
                <img src="/assets/images/common/school_1.png" class="w-100" alt=""/>
                <p>St. Xavier's School, <br>Jaipur</p>
            </div>
            <div class="col-sm-2 text-center">
                <img src="/assets/images/common/school_2.png" class="w-100" alt=""/>
                <p>St. Kabir Public School, <br> Chandigarh</p>
            </div>
            <div class="col-sm-2 text-center">
                <img src="/assets/images/common/school_3.png" class="w-100" alt=""/>
                <p>Genesis Global School, <br> Noida</p>
            </div>
            <div class="col-sm-2 text-center">
                <img src="/assets/images/common/school_4.png" class="w-100" alt=""/>
                <p>Birla Public School, <br> Pilani</p>
            </div>
            <div class="col-sm-2 text-center">
                <img src="/assets/images/common/school_5.png" class="w-100" alt=""/>
                <p>Amity International School, <br> Noida</p>
            </div>
        </div>
        </div>

       


        <div class="col-md-12 mt-50">

        <h4>Get in Touch</h4>
        <p>Whether it’s a question, a suggestion, or a chat over coffee about your next travel adventure, we’d love to hear from you. Feel free to drop by our office; we promise the coffee is as good as the conversation!</p>

      </div>
        
    </div>
</div>

<br><br><br>