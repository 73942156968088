import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaticPageService {

  constructor(private httpService:HttpClient) { }
  aboutus(){
    return this.httpService.get(environment.BASE_URL+environment.ABOUTUS)
  }
  testimonials(){
    return this.httpService.get(environment.BASE_URL+environment.TESTIMONIALS)
  }
  termsandconditionprivacy(){
    return this.httpService.get(environment.BASE_URL+environment.TERMSPRIVACY)
  }
  medicalselfdeclarationclause(){
    return this.httpService.get(environment.BASE_URL+environment.MEDICALDECLARATION)
  }
  liabilities(){
    return this.httpService.get(environment.BASE_URL+environment.LIABILITIES)
  }
}
