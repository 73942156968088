import { Component, OnInit } from '@angular/core';
import { Helpers } from 'src/app/helpers';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {


  constructor(private _router: Router) {}

  ngOnInit() {
    Helpers.initLayout();
  }

}
