import { Component, OnInit } from '@angular/core';
import { StaticPageService } from 'src/app/services/static-page.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  title = 'PR & Marketing';
  data:any;

  constructor(private _static:StaticPageService) { }
  ngOnInit(): void {
    this._static.testimonials().subscribe((obj:any)=>{
      this.data=""
      if(obj?.testimonials){        
        obj?.testimonials.forEach(element => {
          this.data += element.testimonial_description
        });
      }
      
    })
  }

}
