import { Component, OnInit } from '@angular/core';
import { StaticPageService } from 'src/app/services/static-page.service';
import { SharedService } from 'src/app/shared/services/shared.service';
// import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  title = 'About Us';
  data:any;

  constructor(private _static:StaticPageService) { }
  ngOnInit(): void {
    this._static.aboutus().subscribe((obj:any)=>{
      this.data=obj.aboutus.pages.pageDescription
    })
  }

}
