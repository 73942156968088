import { Component, OnInit } from '@angular/core';
import { StaticPageService } from 'src/app/services/static-page.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  title = 'Privacy Policy';
  data:any;

  constructor(private _static:StaticPageService) { }
  ngOnInit(): void {
    this._static.termsandconditionprivacy().subscribe((obj:any)=>{
      this.data=obj?.termsprivacy?.privacypolicy?.description
    })
  }

}
