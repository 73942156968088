import { Injectable } from '@angular/core';
import { env } from 'process';
import { HttpClientService } from 'src/app/services/http-client.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private httpService: HttpClientService) { }

  createParticipant(user: any, userDetails: (status: any, message: any) => any) {
    this.httpService.post(environment.BASE_URL + environment.CREATE_PARTICIPANT, user).subscribe((data: any) => {

      userDetails(data.status, data.message)
    })
  }

  



  submitpartnerWithUsForm(request: any){
    return this.httpService.post(environment.BASE_URL+environment.PARTNERWITHUS,request)
  }
  submitProfileForm(request: any){
    return this.httpService.post(environment.BASE_URL+environment.UPDATEPROFILE,request)
  }
  updateProfile(request: any
    , profile: (res: any) => any) {
    this.httpService.post(environment.BASE_URL + environment.UPDATEPROFILE, request).subscribe((data: any) => {
      profile(data);
    });
  }
  insertUser(request){
    return this.httpService.post(environment.BASE_URL + environment.INSERTUSER, request);
  }
  
  register(request: any
    , profile: (res: any) => any) {
    this.httpService.post(environment.BASE_URL + environment.REGISTER, request).subscribe((data: any) => {
      profile(data);
    });
  }


  partnerWithUs(request: any
    , about: (res: any) => any) {
    this.httpService.post(environment.BASE_URL + environment.PARTNERWITHUS, request).subscribe((data: any) => {
      about(data);
    });
  }


  getRegions(regions: (regions: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.REGIONS).subscribe((data: any) => {
      regions(data.regions);
    });
  }
  getLpregions(regions: (regions: any) => any) {
    this.httpService.get(environment.BASE_URL + environment.LPREGIONS).subscribe((data: any) => {
      regions(data.regions);
    });
  }

}
