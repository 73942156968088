import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AboutusComponent } from './modules/aboutus/aboutus.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PrivacypolicyComponent } from './modules/privacypolicy/privacypolicy.component';
import { TermsandconditionComponent } from './modules/termsandcondition/termsandcondition.component';
import { TestimonialsComponent } from './modules/testimonials/testimonials.component';


const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
      {
        path: 'trek', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      },
      {
        path: 'trip', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      },
      {
        path: 'leisure', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      },
      {
        path: 'hostels', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      },
      {
        path: 'expeditions', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      },
      {
        path: 'trek/:type', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      },
      // {
      //   path: 'page/:type/:subtype', loadChildren: () => import('./modules/tabed-page/tabed-page.module').then(m => m.TabedModule)
      // },
      { path: 'blog', redirectTo: 'http://www.newridingsolo.in/blog', pathMatch: 'full' },


      { path: 'contactus', loadChildren: () => import('./modules/contactus/contactus.module').then(m => m.ContactusModule) },

      { path: 'partner-with-us', loadChildren: () => import('./modules/partner-with-us/partner-with-us.module').then(m => m.PartnerWithUsModule) },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'account', loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule) },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'booknow', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
      { path: 'booknow/transactionhistory', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
      { path: 'booknow/bookinghistory', loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule) },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
      { path: 'testimonials', component: TestimonialsComponent },
      { path: 'termsandcondition/:id', component: TermsandconditionComponent },
      { path: 'privacypolicy', component: PrivacypolicyComponent },
      { path: 'tripwithcelebrity', loadChildren: () => import('./modules/tripwithcelebrity/tripwithcelebrity.module').then(m => m.TripwithcelebrityModule) },






    ]
  },

  // { path: 'trek-detail', loadChildren: () => import('./modules/tabed-page/all-treks/treks-detail/trek-detail/trek-detail.module').then(m => m.TrekDetailModule) }
  //,
  // {
  //   path: 'account', component: AccountsComponent,
  //   children: [
  //     { path: '', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule) }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
